<template>
    <div>
        <p class="text-center message_chargement mb-0 mt-4">
            <div class="loader">
                <svg class="fa-pulse" x="0px" y="0px" width="35px" height="35px" viewBox="0 0 35 35" ><path d="M25.33,3.22l1.31-2.83c0,0-3.91-1.09-7.32,2l-0.34-0.04c0.12-0.31,0.25-0.63,0.39-0.97 c-2.09,0.24-4.08,0.7-5.94,1.45C7,4.62,2.28,10.51,2.28,17.5c0,8.4,6.81,15.22,15.22,15.22c4.03,0,7.69-1.57,10.42-4.12l-0.01-0.01 c0.63-0.6,1.02-1.45,1.02-2.38c0-1.83-1.48-3.31-3.31-3.31c-0.88,0-1.68,0.35-2.27,0.9l0,0c-1.53,1.42-3.58,2.29-5.84,2.29 c-4.69,0-8.5-3.76-8.58-8.44c0.02-4.54,3.63-8.23,8.14-8.38c0,0-1.15,3.32,2.44,5.24c0,0,1.44,0.94,2.96,1.01 c1.52,0.06,2.22,0.65,2.22,0.65c1.43,1.6,4.69,4.05,4.69,4.05c1.89-1.1,3.27-3.53,3.27-3.53C32.27,13.59,25.33,3.22,25.33,3.22z  M24.34,7.92c-0.19-0.18-0.37-0.37-0.56-0.55c-0.08-0.08-0.07-0.12,0.02-0.19c0.41-0.3,0.92-0.3,1.31,0.04 c0.23,0.2,0.42,0.45,0.64,0.68C25.4,8.28,24.74,8.29,24.34,7.92z"/></svg>
                {{ tradText }}
            </div>
        <!-- eslint-disable-next-line vue/no-parsing-error -->
        </p>
    </div>
</template>

<script type="text/javascript">
	export default {
        props: {
            text_trad: {
                type: String,
                default: () => ('global.chargement')
            },
        },
        computed: {
            tradText() {
                if(this.text_trad) {
                    return this.getTrad(this.text_trad)
                }
                return ''
            }
        }
    }
</script>
